import {imtStates} from '@imt/vue-kit-car/src/data/us/states';
import utils, {axiosWithAuth, deepCase} from '@imt/vue-toolbox/src/utils';
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import Vuex from 'vuex';

import {helpWording} from '@/lang';
import {dropdownUtils, transformApiData} from '@/utilities';

import { createApiUrl } from '../../utilities';

Vue.use(Vuex);

export const authAxios = axiosWithAuth();

export const getters = {
    actionsConfig(state, _getters) {
        return {
            actionType: {
                label: 'Action Type',
                options: _getters.actionTypes,
                placeholder: 'Select an action...',
                savedTo: 'action_type',
            },
            form: {
                allowEmpty: true,
                helpWording: 'Select the form to be rolled on or off.',
                isSelectionInput: true,
                label: 'Form',
                options: async({storeContext, policySystemId, $event}) => {
                    const queryParams = $event ? {'filter[text]': $event} : {},
                        forms = await storeContext.dispatch('expressionBuilder/fetchForms', {policySystemId, queryParams});

                    return {options: transformApiData(forms)};
                },
                placeholder: 'Start typing to select a form...',
                savedTo: 'data.form',
            },
            classCode: {
                allowEmpty: true,
                helpWording: 'Select the class code to be rolled on or off.',
                isSelectionInput: true,
                label: 'Class Code',
                options: async({storeContext, policySystemId, $event}) => {
                    const queryParams = $event ? {'filter[text]': $event} : {},

                        classCodes = await storeContext.dispatch('expressionBuilder/fetchClassCodes', {policySystemId, queryParams});

                    return {options: transformApiData(classCodes)};
                },
                placeholder: 'Start typing to select a class code...',
                savedTo: 'data.class_code',
            },
            page: {
                helpWording: 'Select the page to be redirected to.',
                label: 'Redirect to Page',
                options: _getters.pageOptions,
                placeholder: 'Select a page...',
                savedTo: 'data.page',
            },
            state: {
                allowEmpty: true,
                label: 'State',
                options: _getters.companyStates,
                placeholder: 'Select a state...',
                savedTo: 'data.state',
            },
            fieldTarget: {
                label: 'Target',
                isDotNotationInput: true,
                options: {},
                order: [],
                placeholder: '',
                savedTo: 'data.target'
            },
            uassist: {
                label: 'U-Assist',
                options: _getters.uassistOptions,
                placeholder: 'Select a U-Assist...',
                savedTo: 'data.code',
            },
        };
    },
    actionTypes: (state) => () => {
        // Determine the available action types based on the rule type.
        if (state.linkedPageData.ruleType === 'page_display') {
            return {
                options: {
                    hide_component: 'Hide a component',
                    show_component: 'Show a component',
                    set_value: 'Set Value',
                },
                order: ['hide_component', 'show_component', 'set_value'],
            };
        } else if (state.linkedPageData.ruleType === 'page_validation') {
            return {
                options: {
                    screen_error: 'Screen Error',
                },
            };
        } else if (state.linkedPageData.ruleType === 'clean_up') {
            return {
                options: {
                    set_value: 'Set Value',
                    roll_on_class_code: 'Roll on a class code',
                    roll_off_class_code: 'Roll off a class code',
                    roll_on_form: 'Roll on a form',
                    roll_off_form: 'Roll off a form',
                },
            };
        }

        // Cross validation actions
        return {
            options: {
                agent_remark: 'Agent Remark',
                page_redirect: 'Redirect to a page',
                screen_error: 'Screen Error',
                uassist_error: 'U-Assist Error',
                uassist_info: 'U-Assist Informational Message',
                uassist_warning: 'U-Assist Warning',
            },
            order: ['agent_remark', 'screen_error', 'page_redirect', 'uassist_error', 'uassist_warning', 'uassist_info'],
        };
    },
    allDropDownsLoaded(state) {
        return !Object.values(state.dropdownsLoading).some(loading => loading);
    },
    allowedComponentTypes: (state) => ({
        component,
        parentSubType,
    }) => {
        let options = dropdownUtils.constants.componentTypes,
            order = ['object', 'literal', 'method'];

        if (parentSubType === 'math' && component.position === 1) {
            // Mathematical methods have an operator as their second child.
            order = ['operator'];
        } else if (parentSubType && component.position !== 1) {
            order = ['object', 'literal'];
        } else if (!parentSubType && component.position === 1) {
            // The second top-level component in an expression is always the condition.
            order = ['condition'];
        } else if (state.linkedPageData.pageId) {
            // For page display and page validation rules, show the "Screen Field" component type.
            order = ['object', 'literal', 'method'];
        }

        return {
            options,
            order,
        };
    },
    childDropdownConfig(state, _getters) {
        return {
            agent_remark: ['error_message'],
            hide_component: ['component_sync_id'],
            page_redirect: ['page', 'component_sync_id'],
            roll_on_class_code: ['classCode', 'state'],
            roll_off_class_code: ['classCode', 'state'],
            roll_on_form: ['form', 'state'],
            roll_off_form: ['form', 'state'],
            set_value: ['fieldTarget', 'field_value'],
            screen_error: _getters.screenErrorFields,
            show_component: ['component_sync_id'],
            uassist_error: ['uassist'],
            uassist_info: ['uassist'],
            uassist_warning: ['uassist'],
        };
    },
    classCodeOptions(state) {
        return transformApiData(state.classCodes);
    },
    companyStates(state) {
        if (Object.keys(state.company).length) {
            return state.company.licensedStates.reduce((result, item) => {
                const key = item.abbreviation;
                if (key in imtStates) {
                    result[key] = imtStates[key];
                }

                return result;
            }, {});
        }
    },
    componentTypeHelpWording: () => ({order}) => {
        let wordingOptions = {
            condition: 'A <b>Condition</b> is a comparison operator like "Less than" or "Equals".',
            literal: 'A <b>Literal</b> is a "hard-coded" value that does not change.',
            method: 'A <b>Method</b> is a function for calculating a value, such as a mathematical expression, or an ' +
                'aggregate method like counting the number of objects.',
            object: 'An <b>Object</b> refers to a specific piece of saved policy data, like a field or form.',
            operator: 'An <b>Operator</b> is a mathematical operator like Plus or Minus.',
        };

        return `<p>${order.map(value => wordingOptions[value]).join('</p><p>')}</p>`;
    },
    conditionOptions: () => ({component}) => {
        const isDateComparison = component.data.comparisonType === 'date';
        if (isDateComparison) {
            return {options: dropdownUtils.constants.dateConditions};
        }

        return {options: dropdownUtils.constants.conditions};
    },
    dropdownsConfig(_, _getters) {
        return {
            componentType: {
                label: 'Component Type',
                helpWording: _getters.componentTypeHelpWording,
                options: _getters.allowedComponentTypes,
                placeholder: 'Select a component type...',
            },
            comparisonType: {
                label: 'Comparison Type',
                helpWording: 'The type of the data that will be compared with this condition.',
                default: 'string',
                options: dropdownUtils.constants.comparisonTypes,
                placeholder: 'Select a comparison type...'
            },
            conditionType: {
                label: 'Condition Type',
                helpWording: '',
                options: _getters.conditionOptions,
                placeholder: 'Select a condition...',
            },
            literalType: {
                default: 'single',
                label: 'Literal Type',
                helpWording: '',
                options: dropdownUtils.constants.literalTypes,
                placeholder: 'Select a literal type...',
            },
            matchType: {
                allowEmpty: true,
                default: 'every',
                label: 'Match Type',
                helpWording: 'Indicate the match conditions for the expression. A type of "Every" means that every item being ' +
                    'compared must meet the condition, while a type of "Any" means that only one set of items being compared ' +
                    'need to meet the condition. For example, in a condition "Form Field X is greater than 5", if there are ' +
                    'multiple instances of Form Field X on the policy, each of them must be greater than 5 with a Match Type ' +
                    'of "Every", but with a Match Type of "Any" only one of them needs to be greater than 5.',
                options: dropdownUtils.constants.matchTypes,
                placeholder: 'Select a match type...',
            },
            mathematicalMethodType: {
                label: 'Mathematical Operator Type',
                helpWording: '',
                options: dropdownUtils.constants.mathematicalMethodTypes,
                placeholder: 'Select a mathematical method type...',
            },
            methodType: {
                label: 'Method Type',
                helpWording: '',
                options: dropdownUtils.constants.methodTypes,
                placeholder: 'Select a method type...',
            },
            objectType: {
                label: 'Object Type Path',
                isDotNotationInput: true,
                helpWording: helpWording.en.rules.adding.objectType,
                options: {},
                order: [],
                placeholder: '',
            },
            operator: {
                label: '',
                helpWording: '',
                options: dropdownUtils.constants.operators,
                placeholder: 'Select an operator...',
            },
            unitType: {
                default: 'days',
                label: 'Unit Type',
                helpWording: '',
                options: dropdownUtils.constants.unitTypes,
                placeholder: '',
            },
            version: {
                label: 'Version',
                default: 'current_version',
                helpWording: 'Indicates which version of the policy to use for data. <p><b>Current Version</b> refers to the ' +
                    'current transaction of the policy being filled out.</p><p><b>Previous Version</b> refers to the version ' +
                    'that most recently preceded this version, by effective date.</p><p>For example, if a policy was being ' +
                    'amended for the first time, "Current Version" would refer to the amendment, and "Previous Version" ' +
                    'would refer to the original new business transaction.</p>',
                options: dropdownUtils.constants.versionOptions,
                placeholder: 'Select a transaction version...',
            },
        };
    },
    formOptions(state) {
        return transformApiData(state.forms);
    },
    pageOptions(state) {
        return transformApiData(state.pages);
    },
    screenErrorFields(state) {
        let fields = ['error_message'];

        if (state.linkedPageData.ruleType === null) {
            // Show a selector for which page and component to redirect to on cross validation rules.
            fields = fields.concat(['page', 'component_sync_id']);
        } else {
            // Show a selector for which component to display the error next to for page validation rules.
            fields = fields.concat(['component_sync_id']);
        }

        return fields;
    },
    uassistOptions: (state) => ({actionType, excludeCode}) => {
        let options = {};

        if (state.uassists.length) {
            for (let uassist of state.uassists) {
                if (`uassist_${uassist.uassistType}` === actionType) {
                    if (excludeCode) {
                        options[uassist.uassistCode] = uassist.description;
                    } else {
                        options[uassist.uassistCode] = `${uassist.uassistCode}: ${uassist.description}`;
                    }
                }
            }
        }

        return {options: options};
    },
};

export const actions = {
    async fetchClassCodes({commit}, {policySystemId, queryParams = {}}) {
        const url = createApiUrl(
                `${process.env.VUE_APP_ADMIN_URL_FORMS}/api/v1/policy-systems/${policySystemId}/class-codes/?include=versions`,
                queryParams
            ),
            response = await authAxios.get(url),
            classCodes = utils.dataFormatter.deserialize(response.data);

        commit('SET_EXPRESSION_BUILDER_DATA', {field: 'classCodes', data: classCodes});

        return classCodes;
    },
    async fetchCompany({commit}, companyId) {
        const response = await authAxios.get(`${process.env.VUE_APP_ADMIN_URL_PLATFORM_ADMIN}/api/v1/companies/${companyId}/`);

        commit('SET_EXPRESSION_BUILDER_DATA', {field: 'company', data: utils.dataFormatter.deserialize(response.data)});
    },
    async fetchForms({commit}, {policySystemId, queryParams = {}}) {
        const url = createApiUrl(
                `${process.env.VUE_APP_ADMIN_URL_FORMS}/api/v1/policy-systems/${policySystemId}/forms/?include=versions`,
                queryParams
            ),
            response = await authAxios.get(url),
            forms = utils.dataFormatter.deserialize(response.data);
        commit('SET_EXPRESSION_BUILDER_DATA', {field: 'forms', data: forms});

        return forms;
    },
    async fetchPages({commit}, {policySystemId, queryParams = {}}) {
        const url = createApiUrl(
                `${process.env.VUE_APP_ADMIN_URL_CMS}/api/v1/policy-systems/${policySystemId}/pages/?include=versions`,
                queryParams
            ),
            response = await authAxios.get(url),
            pages = utils.dataFormatter.deserialize(response.data);

        commit('SET_EXPRESSION_BUILDER_DATA', {field: 'pages', data: pages});

        return pages;
    },
    async fetchPolicyDataModelFields(_, model) {
        const response = await authAxios.get(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/models/${model}/`);

        return response.data;
    },
    async fetchUassists({commit}) {
        const response = await authAxios.get(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/uassists/legacy/`);
        let uassists = deepCase(response.data.data);

        commit('SET_EXPRESSION_BUILDER_DATA', {field: 'uassists', data: uassists});

        return uassists;
    },
};

export const mutations = {
    ADD_COMPONENT(state, component) {
        state.components.push(component);
    },
    ADD_EXPRESSION(state, expression) {
        state.expressions.push(expression);
    },
    ADD_EXPRESSION_GROUP(state, group) {
        state.expressionGroups.push(group);
    },
    CLEAR_DROPDOWN_LOADING(state) {
        state.dropdownsLoading = {};
    },
    DELETE_COMPONENT(state, componentId) {
        let componentIndex = state.components.findIndex(component => component.id === componentId);

        Vue.delete(state.components, componentIndex);
    },
    DELETE_EXPRESSION(state, expressionId) {
        let expressionIndex = state.expressions.findIndex(expression => expression.id === expressionId);

        Vue.delete(state.expressions, expressionIndex);
    },
    DELETE_EXPRESSION_GROUP(state, groupId) {
        let groupIndex = state.expressionGroups.findIndex(expressionGroup => expressionGroup.id === groupId);

        Vue.delete(state.expressionGroups, groupIndex);
    },
    SET_COMPONENT(state, {
        component,
        ...props
    }) {
        if (!component?.parent && !component?.parentId) {
            let componentIndex = state.components.findIndex(c => c.id === component.id);
            Vue.set(state.components, componentIndex, {...component, ...props});
        } else {
            let parentId = component?.parentId ? component.parentId : component.parent.id,
                parentIndex = state.components.findIndex(c => c.id === parentId),
                componentIndex = state.components[parentIndex].children.findIndex(child => child.id === component.id);

            Vue.set(state.components[parentIndex].children, componentIndex, {...component, ...props});
        }
    },
    SET_EXPRESSION_BUILDER_DATA(state, {field, data}) {
        state[field] = data;
    },
    SET_EXPRESSION(state, {
        expression,
        ...props
    }) {
        let index = state.expressions.findIndex(e => e.id === expression.id);

        Vue.set(state.expressions, index, {...expression, ...props});
    },
    SET_EXPRESSION_GROUP(state, {
        group,
        ...props
    }) {
        let index = state.expressionGroups.findIndex(expressionGroup => expressionGroup.id === group.id);

        Vue.set(state.expressionGroups, index, {...group, ...props});
    },
    SET_DROPDOWN_VALUE(state, {
        parentType,
        parentId,
        index,
        value,
    }) {
        Vue.set(state.dropdowns[parentType][parentId][index], 'value', value);
    },
    SET_DROPDOWNS(state, {
        componentId,
        dropdowns,
        type,
    }) {
        Vue.set(state.dropdowns[type], componentId, dropdowns);
    },
    SET_DROPDOWN_LOADING(state, {
        id,
        value,
    }) {
        Vue.set(state.dropdownsLoading, id, value);
    },
    SET_LEFT_HAND_COMPONENT(state, {
        expressionId,
        componentId,
    }) {
        Vue.set(state.leftHandComponents, expressionId, componentId);
    },
    SET_EXPRESSION_GROUPS(state, expressionGroups) {
        let groups = [],
            expressions = [],
            components = [];

        for (let expressionGroup of expressionGroups) {
            groups.push(cloneDeep(expressionGroup));
            expressions = expressions.concat(cloneDeep(expressionGroup.expressions));

            for (let expression of expressionGroup.expressions) {
                components = components.concat(cloneDeep(expression.components));

                for (let childComponent of state.components) {
                    components = components.concat(cloneDeep(childComponent.children || []));
                }
            }
        }

        state.expressionGroups = groups;
        state.expressions = expressions;
        state.components = components;
    },
};

export const state = () => {
    return {
        classCodes: [],
        company: {},
        forms: [],
        pages: [],
        uassists: [],
        dropdowns: {
            action: {},
            component: {},
            expression: {},
            group: {},
        },
        dropdownsLoading: {},
        expressionGroups: [],
        expressions: [],
        components: [],
        leftHandComponents: {},
        linkedPageData: {
            pageId: null,
            pageType: null,
            ruleType: null,
        },
    };
};

export default {
    actions,
    mutations,
    getters,
    namespaced: true,
    state: state()
};
